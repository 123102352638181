export enum ElementId {
  MainStates = 'mainStates',
  LoadingState = 'loadingState',
  LoadedState = 'loadedState',
  StatusText = 'statusText',
  EarnPointsText = 'earnPointsText',
  ContentStates = 'contentStates',
  NotLoggedInState = 'notLoggedInState',
  NotEnoughPointsState = 'notEnoughPointsState',
  DiscountRewardState = 'discountRewardState',
  CodeAppliedState = 'codeAppliedState',
  CodeAppliedText = 'codeAppliedText',
  LogInText = 'logInText',
  LogInButton = 'logInButton',
  DiscountRewardWidget = 'discountRewardWidget',
  NotEnoughPointsWidget = 'notEnoughPointsWidget',
  ApplyRewardButton = 'applyRewardButton',
  ProgramIcon = 'programIcon',
}

export const DISCOUNT_REWARD_COUPON_NAME = 'Loyalty - order discount';
export const ICON_BASE_URL = 'https://static.wixstatic.com';
